import React from "react"
import { Layout } from "../components/layout"
import { graphql } from "gatsby"
import { HomepageContainer } from "../containers/homepage-container"

export default function Home({ data }) {
  return (
    <Layout>
      <HomepageContainer data={data} />
    </Layout>
  )
}

export const query = graphql`
  query {
    headerImage: file(relativePath: { regex: "/jakob-owens-pug/" }) {
      childImageSharp {
        fixed(width: 640, height: 640, fit: COVER, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
