import React, { useContext } from "react"
import { Link } from "../components/Link"
import {
  Button,
  Hero,
  HeroColumns,
  HeroImage,
  Container,
  FeatureHighlightGroup,
  FeatureHighlight,
} from "@revfluence/aspire-www-ui"
import Img from "gatsby-image/withIEPolyfill"
import { PageSection } from "../components/page-section"
import SEO from "../components/seo"
import { ModalContext } from "@revfluence/aspire-www-ui"

export const HomepageContainer = ({ data }) => {
  const { dispatch } = useContext(ModalContext)
  console.log(dispatch)
  console.log("123")
  return (
    <React.Fragment>
      <div></div>
      <SEO
        title="AspireIQ | Your guide to 2020 holiday campaigns"
        description="Engage your brand community during the biggest shopping season of the year. Explore this resource center to discover how to boost sales during the holidays"
        url="holiday.aspireiq.com"
      />

      <Hero>
        <HeroColumns
          headline="Your guide to 2020 holiday campaigns"
          Copy={() => (
            <div>
              Explore this resource center to discover how to boost sales during
              the holidays
            </div>
          )}
          Cta={(props) => {
            return (
              <div style={props.style}>
                <Button onClick={() => dispatch({ type: "OPEN_MODAL" })}>
                  Request Demo
                </Button>
              </div>
            )
          }}
          style={""}
          Media={(p) => {
            return (
              <HeroImage
                Media={(props) => (
                  <Img
                    fixed={data.headerImage.childImageSharp.fixed}
                    {...props}
                  />
                )}
              />
            )
          }}
        >
          {null}
        </HeroColumns>
      </Hero>
      <Container>
        <PageSection>
          <FeatureHighlightGroup maxColumnCount={3}>
            <FeatureHighlight
              headline="Stats"
              icon="chart-pie"
              copy="Get insights that will fuel your brand campaigns this season — and amplify your community efforts"
              Link={Link}
              url={"/data"}
            />
            <FeatureHighlight
              headline="Strategies"
              icon="lightbulb"
              copy="Implement strategies that empower you to connect with everyone who is valuable to your brand."
              Link={Link}
              url={"/strategies"}
            />
            <FeatureHighlight
              headline="Step-by-steps"
              icon="list"
              copy="Need extra guidance? We have tips and timelines to walk you through end-to-end campaign."
              Link={Link}
              url={"/q-and-a"}
            />
          </FeatureHighlightGroup>
        </PageSection>
      </Container>
    </React.Fragment>
  )
}
